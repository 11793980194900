<template>
  <div>
    <FormulateForm
      ref="form"
      name="formStore"
    >
      <b-card-actions
        ref="main-card"
        no-actions
        :show-loading="loading || busy"
        :title="$t('Dados Cadastrais')"
      >
        <b-row>
          <b-col md="3">
            <e-company-combo
              id="company-id"
              v-model="store.companyId"
              name="company-id"
              :required="true"
              :label="$t('Empresa')"
              @input="loadCompanyData(store.companyId)"
            />
          </b-col>
          <b-col md="1">
            <FormulateInput
              id="store-id"
              v-model="store.id"
              name="id"
              class="required"
              :label="$t('Id')"
              :type="isEdition ? 'label' : 'number'"
              validation="required"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="identification"
              v-model="store.identification"
              name="identification"
              class="required"
              :label="$t('Identificação')"
              placeholder="M01, F01, Q01, etc."
              type="text"
              validation="required"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="company-name"
              v-model="store.companyName"
              name="companyName"
              :label="$t('Razão Social')"
              type="label"
              :instruction="$t('Herdado da empresa selecionada')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="trading-name"
              v-model="store.tradingName"
              name="tradingName"
              type="text"
              class="required"
              :label="$t('Nome Fantasia')"
              validation="required"
            />
          </b-col>
          <b-col
            v-show="false"
            md="3"
          >
            <e-store-combo
              id="order-store_id"
              v-model="store.headquartersStoreId"
              name="headquartersStoreId"
              :required="false"
              :label="$t('Matriz')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="type"
              v-model="store.type"
              name="type"
              :type="isEdition && store.type === 'Kiosk' ? 'label' : 'vue-select'"
              class="required"
              validation="required"
              :label="$t('Tipo')"
              :options="storeTypes()"
              :placeholder="$t('Selecione')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="region"
              v-model="store.region"
              name="region"
              type="label"
              :label="$t('Região')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="businessModel"
              v-model="store.businessModel"
              name="businessModel"
              type="vue-select"
              class="required"
              validation="required"
              :label="$t('Modelo')"
              :options="storeBusinessModels()"
              :placeholder="$t('Selecione')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="headId"
              v-model="store.headId"
              name="headId"
              type="vue-select"
              class="required"
              validation="required"
              :label="$t('Head')"
              :options="headOptions"
              :placeholder="$t('Selecione')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="directorId"
              v-model="store.directorId"
              name="directorId"
              type="vue-select"
              :label="$t('Diretor')"
              :options="directorOptions"
              :placeholder="$t('Selecione')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="directorCommercialId"
              v-model="store.directorCommercialId"
              name="directorCommercialId"
              type="vue-select"
              :label="$t('Diretor Comercial')"
              :options="directorCommercialOptions"
              :placeholder="$t('Selecione')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="invoice-serie-number"
              v-model="store.invoiceSerialNumber"
              name="invoiceSerialNumber"
              type="number"
              class="required"
              :label="$t('Série da NFe')"
              :instruction="$t('Série utilizado para emissão da NF-e')"
              validation="required"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="cnpj"
              v-model="store.cnpj"
              name="cnpj"
              class="required"
              filter="cpfCnpj"
              :label="$t('CNPJ')"
              type="label"
              :instruction="$t('Herdado da empresa selecionada')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="state-registration"
              v-model="store.stateRegistration"
              name="stateRegistration"
              class="required"
              validation="required"
              maxlength="20"
              :label="$t('Inscrição Estadual')"
              type="label"
              :instruction="$t('Herdado da empresa selecionada')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="state-registration-of-tax-substitute"
              v-model="store.stateRegistrationOfTaxSubstitute"
              name="stateRegistrationOfTaxSubstitute"
              maxlength="20"
              :label="$t('I.E. do Substituto Tributário')"
              type="label"
              :instruction="$t('Herdado da empresa selecionada')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="municipal-inscription"
              v-model="store.municipalInscription"
              name="municipalInscription"
              maxlength="20"
              :label="$t('Inscrição Municipal')"
              type="label"
              :instruction="$t('Herdado da empresa selecionada')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="investors"
              v-model="store.investors"
              name="investors"
              type="vue-select"
              multiple
              :label="$t('Investidores')"
              :options="investorsOptions"
              :placeholder="$t('Selecione')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="email"
              v-model="store.email"
              name="email"
              type="text"
              class="required"
              :label="$t('E-mail')"
              validation="required"
            />
          </b-col>
          <b-col md="1">
            <p class="h6">
              Ativo?
            </p>
            <e-status-badge
              :status="!!store.active"
              type="yesNo"
            />
          </b-col>
        </b-row>
      </b-card-actions>

      <b-card-actions
        no-actions
        :show-loading="loading || busy"
        :title="$t('Delivery')"
      >
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="store-delivery"
              v-model="store.delivery"
              name="delivery"
              type="switch"
              :label="$t('Oferece Delivery?')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="email-delivery"
              v-model="store.emailDelivery"
              name="emailDelivery"
              type="text"
              :class="store.delivery ? 'required' : ''"
              :label="$t('E-mail Delivery')"
              :instruction="$t('E-mail usado para o processo de entregas')"
              :validation="store.delivery ? 'required' : ''"
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              id="max-schedule"
              v-model="store.maxScheduleDays"
              name="maxScheduleDays"
              type="text-number"
              :class="store.delivery ? 'required' : ''"
              :label="$t('Qtd. dias para entrega')"
              :instruction="$t('Quantidade máxima de dias para entrega de pedidos.')"
              :validation="store.delivery ? 'required' : ''"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="min-delivery-schedule-minutes"
              v-model="store.minDeliveryScheduleMinutes"
              name="minDeliveryScheduleMinutes"
              type="text-number"
              :class="store.delivery ? 'required' : ''"
              :label="$t('Tempo mínimo para entrega')"
              :instruction="$t('Tempo mínimo em minutos para iniciar a entrega de pedidos.')"
              :validation="store.delivery ? 'required' : ''"
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              id="min-zero-rate-value"
              v-model="store.zeroRateMinValue"
              name="zeroRateMinValue"
              type="text-number"
              currency="R$"
              :precision="2"
              :label="$t('Valor min. p/ taxa zero')"
              :instruction="$t('Valor mínimo de pedido para taxa zero.')"
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              id="shipping-fee-delivery"
              v-model="store.shippingFees.shippingFee"
              name="shippingFee"
              type="text-number"
              currency="R$"
              :precision="2"
              :class="store.delivery ? 'required' : ''"
              :label="$t('Valor da taxa única')"
              :instruction="$t('Valor fixo cobrado pela entrega.')"
              :validation="store.delivery ? 'required' : ''"
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              id="shipping-fee-per-km-delivery"
              v-model="store.shippingFees.shippingFeePerKilometer"
              name="shippingFeePerKilometer"
              type="text-number"
              currency="R$"
              :precision="2"
              :class="store.delivery ? 'required' : ''"
              :label="$t('Valor da taxa por KM')"
              :instruction="
                $t('Valor cobrado pela entrega por quilômetro rodado até o endereço de destino.')
              "
              :validation="store.delivery ? 'required' : ''"
            />
          </b-col>

          <b-col md="3">
            <FormulateInput
              id="deliveryType"
              v-model="store.deliveryType"
              name="deliveryType"
              type="vue-select"
              :instructions="[
                { text: $t('Tipo de entregas que a loja realiza') },
                { text: $t('Cidade: realiza entregas na cidade inteira.') },
                {
                  text: $t('Polígono: realiza entregas dentro de uma determinada área da cidade.'),
                },
              ]"
              :label="$t('Tipo do Delivery')"
              :options="storeDeliveryTypes()"
              :placeholder="$t('Selecione')"
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              id="store-delivery-others-cities"
              v-model="store.deliveryOthersCities"
              name="deliveryOthersCities"
              type="switch"
              :instruction="
                $t('Permite adicionar outras cidades que a loja poderá realizar entrega.')
              "
              :label="$t('Entrega em outras cidades?')"
            />
          </b-col>
        </b-row>

        <b-row v-if="store.deliveryOthersCities">
          <b-col
            md="12"
            class="d-flex justify-content-end mb-1"
          >
            <e-button
              variant="primary"
              :text="$t('Adicionar Cidade')"
              @click="onShowDeliveryLocationSidebar()"
            />
          </b-col>

          <b-col md="12">
            <b-table
              id="stores-other-cities-table"
              class="bordered"
              responsive
              striped
              show-empty
              :empty-text="getEmptyTableAddMessage($tc('ADDRESS.CITY_NAME'), 'female')"
              :items="getStoreDeliveryLocations"
              :fields="deliveryLocationFields"
            >
              <template #cell(actions)="row">
                <e-grid-actions
                  :show-update="false"
                  :show-delete="true"
                  @delete="onRemoveDeliveryLocation(row.item)"
                />
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-card
          :title="$t('App B2B')"
          class="bg-light-gray"
        >
          <FormulateInput
            ref="shippingRangeGroup"
            v-model="store.shippingFees.shippingRanges"
            name="shippingRanges"
            type="group"
            error-behavior="live"
            :repeatable="true"
            :add-label="$t('Adicionar faixa de taxa de delivery')"
            :add-disabled="true"
            minimum="0"
            :label="$t('Faixas de valor para taxa de delivery')"
            @repeatableAdded="recalculateRanges(true)"
            @repeatableRemoved="recalculateRanges(false)"
          >
            <template #default="{ index }">
              <b-row v-if="store.shippingFees">
                <b-col md="3">
                  <FormulateInput
                    :id="`store-shipping-range-price-from-${index}`"
                    type="text-number"
                    filter="currency"
                    currency="R$"
                    :precision="2"
                    name="saleValueFrom"
                    class="required"
                    :disabled="true"
                    :label="$t('De')"
                  />
                </b-col>
                <b-col md="3">
                  <FormulateInput
                    :id="`store-shipping-range-price-to-${index}`"
                    type="text-number"
                    filter="currency"
                    currency="R$"
                    :precision="2"
                    name="saleValueTo"
                    :label="$t('Até')"
                    :validation="generateValidation(index)"
                    @input="recalculateRanges(true)"
                  />
                </b-col>
                <b-col md="3">
                  <FormulateInput
                    :id="`store-shipping-range-type-${index}`"
                    name="valueType"
                    type="vue-select"
                    :options="[
                      { value: 'Value', label: 'R$' },
                      { value: 'Percentage', label: '%' },
                    ]"
                    :label="$t('Valor em')"
                  />
                </b-col>
                <b-col md="3">
                  <FormulateInput
                    :id="`store-shipping-range-value-${index}`"
                    :type="isDiscountPercent(index) ? 'text-percetage' : 'text-number'"
                    name="value"
                    :currency="isDiscountPercent(index) ? '%' : 'R$'"
                    :currency-symbol-position="isDiscountPercent(index) ? 'suffix' : 'prefix'"
                    :label="isDiscountPercent(index) ? $t('Percentual') : $t('Valor')"
                    :precision="2"
                  />
                </b-col>
              </b-row>
            </template>
          </FormulateInput>
        </b-card>
      </b-card-actions>

      <b-card-actions
        ref="main-card"
        no-actions
        :show-loading="loading || busy"
        :title="$t('Loja Virtual')"
      >
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="active-online"
              v-model="store.activeOnline"
              name="activeOnline"
              type="switch"
              :label="$t('Venda no Site')"
              :instruction="
                $t('Quando habilitado, a loja estará disponível para venda no site e no app')
              "
            />
          </b-col>
        </b-row>
        <b-card
          :title="$t('App B2C')"
          class="bg-light-gray"
        >
          <b-row>
            <b-col md="3">
              <FormulateInput
                id="max-product-items-sell"
                v-model="store.maxProductItemsSell"
                name="maxProductItemsSell"
                type="number"
                :label="$t('Qtd. Máxima de itens')"
                :instruction="
                  $t(
                    'Indica quantos itens do mesmo produto a loja pode vender nos canais digitais B2C'
                  )
                "
              />
            </b-col>
            <b-col md="3">
              <FormulateInput
                id="max-order-value"
                v-model="store.maxOrderValue"
                name="maxOrderValue"
                type="text-number"
                currency="R$"
                :precision="2"
                :label="$t('Valor máx. de venda')"
                :instruction="$t('Valor máximo permitido para uma venda')"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <FormulateInput
                id="online-payment-pix"
                v-model="store.onlinePaymentConfig.pix"
                name="onlinePaymentPix"
                type="switch"
                :label="$t('Pagto Pix (online)')"
                :instruction="
                  $t('Quando habilitado, a loja aceitará pagamento via pix no site e no app')
                "
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                id="online-payment-credit"
                v-model="store.onlinePaymentConfig.credit"
                name="onlinePaymentCredit"
                type="switch"
                :label="$t('Pagto Crédito (online)')"
                :instruction="
                  $t(
                    'Quando habilitado, a loja aceitará pagamento via cartão de crédito no site e no app'
                  )
                "
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                id="online-payment-cash"
                v-model="store.onlinePaymentConfig.cash"
                name="onlinePaymentCash"
                type="switch"
                :label="$t('Pagto Dinheiro (na entrega)')"
                :instruction="
                  $t(
                    'Quando habilitado, a loja aceitará pagamentos em dinheiro na entrega de pedidos feitos no site e app'
                  )
                "
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                id="online-payment-pickup"
                v-model="store.onlinePaymentConfig.payOnPickup"
                name="onlinePaymentPayOnPickup"
                type="switch"
                :label="$t('Pagto Cartão (na entrega)')"
                :instruction="
                  $t(
                    'Quando habilitado, a loja aceitará pagamentos em cartão de crédito/débito na entrega de pedidos feitos no site e app'
                  )
                "
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                id="online-chat-enabled"
                v-model="store.onlineChatEnabled"
                name="onlineChatEnabled"
                type="switch"
                :label="$t('Chat ativo')"
                :instruction="
                  $t('Quando habilitado, a loja poderá ser acionada pelos clientes via chat no app')
                "
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <FormulateInput
                id="online-payment-pix-limit"
                v-model="store.onlinePaymentConfig.pixLimit"
                name="onlinePaymentPixLimit"
                type="text-number"
                currency="R$"
                :precision="2"
                :label="$t('Limite Pix')"
                :instruction="$t('Limite de valor para pagamento no PIX no site e no app')"
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                id="online-payment-credit-limit"
                v-model="store.onlinePaymentConfig.creditLimit"
                name="onlinePaymentCreditLimit"
                type="text-number"
                currency="R$"
                :precision="2"
                :label="$t('Limite Crédito')"
                :instruction="
                  $t('Limite de valor para pagamento com cartão de crédito no site e no app')
                "
              />
            </b-col>
            <b-col
              v-if="false"
              md="2"
            >
              <FormulateInput
                id="online-payment-cash-limit"
                v-model="store.onlinePaymentConfig.cashLimit"
                name="onlinePaymentCashLimit"
                type="text-number"
                currency="R$"
                :precision="2"
                :label="$t('Limite Dinheiro')"
                :instruction="$t('Limite de valor para pagamento em dinheiro no site e no app')"
              />
            </b-col>
          </b-row>
        </b-card>
        <b-card
          :title="$t('App B2B')"
          class="bg-light-gray"
        >
          <b-row>
            <b-col md="2">
              <FormulateInput
                id="online-payment-pix-B2B"
                v-model="store.onlinePaymentB2BConfig.pix"
                name="onlinePaymentPixB2B"
                type="switch"
                :label="$t('Pagto Pix (online)')"
                :instruction="$t('Quando habilitado, a loja aceitará pagamento via pix no App B2B')"
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                id="online-payment-credit-B2B"
                v-model="store.onlinePaymentB2BConfig.credit"
                name="onlinePaymentCreditB2B"
                type="switch"
                :label="$t('Pagto Crédito (online)')"
                :instruction="
                  $t(
                    'Quando habilitado, a loja aceitará pagamento via cartão de crédito no App B2B'
                  )
                "
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                id="online-payment-cash-B2B"
                v-model="store.onlinePaymentB2BConfig.cash"
                name="onlinePaymentCashB2B"
                type="switch"
                :label="$t('Pagto Dinheiro (na entrega)')"
                :instruction="
                  $t(
                    'Quando habilitado, a loja aceitará pagamentos em dinheiro na entrega de pedidos feitos no site e App B2B'
                  )
                "
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                id="online-payment-pickup-B2B"
                v-model="store.onlinePaymentB2BConfig.payOnPickup"
                name="onlinePaymentPayOnPickupB2B"
                type="switch"
                :label="$t('Pagto Cartão (na entrega)')"
                :instruction="
                  $t(
                    'Quando habilitado, a loja aceitará pagamentos em cartão de crédito/débito na entrega de pedidos feitos no site e App B2B'
                  )
                "
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <FormulateInput
                id="online-payment-pix-limit-B2B"
                v-model="store.onlinePaymentB2BConfig.pixLimit"
                name="onlinePaymentPixLimitB2B"
                type="text-number"
                currency="R$"
                :precision="2"
                :label="$t('Limite Pix')"
                :instruction="$t('Limite de valor para pagamento no PIX no App B2B')"
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                id="online-payment-credit-limit-B2B"
                v-model="store.onlinePaymentB2BConfig.creditLimit"
                name="onlinePaymentCreditLimitB2B"
                type="text-number"
                currency="R$"
                :precision="2"
                :label="$t('Limite Crédito')"
                :instruction="$t('Limite de valor para pagamento com cartão de crédito no App B2B')"
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                id="stock-percentage-B2B"
                v-model="store.stockPercentageForB2B"
                name="stockPercentageForB2B"
                type="text-percetage"
                currency="%"
                currency-symbol-position="suffix"
                :precision="2"
                :label="$t('% Estoque B2B')"
                :instruction="
                  $t(
                    'Percentual do estoque que será disponibilizado para comercialização no App B2B'
                  )
                "
              />
            </b-col>
            <b-col
              v-if="false"
              md="2"
            >
              <FormulateInput
                id="online-payment-cash-limit-B2B"
                v-model="store.onlinePaymentB2BConfig.cashLimit"
                name="onlinePaymentCashLimitB2B"
                type="text-number"
                currency="R$"
                :precision="2"
                :label="$t('Limite Dinheiro')"
                :instruction="$t('Limite de valor para pagamento em dinheiro no site e no app')"
              />
            </b-col>
          </b-row>
        </b-card>
        <b-row>
          <b-col md="6">
            <FormulateInput
              v-model="imageWideLocal"
              name="imageWideLocal"
              type="uploader"
              deletable="true"
              max-files="1"
              :meta="false"
              :label="$t('Imagem Wide')"
            />
          </b-col>
          <b-col md="6">
            <FormulateInput
              v-model="imageSquareLocal"
              name="imageSquareLocal"
              type="uploader"
              deletable="true"
              max-files="1"
              :meta="false"
              :label="$t('Imagem Quadrada')"
            />
          </b-col>
        </b-row>
      </b-card-actions>

      <b-card-actions
        v-if="store.type === 'Bar'"
        no-actions
        :show-loading="loading || busy"
        :title="$t('Bar')"
      >
        <b-row md="5">
          <b-col md="12">
            <FormulateInput
              id="waiter-comission"
              v-model="store.waiterComission"
              name="waiterComission"
              type="switch"
              :label="$t('Habilitar gorjeta do garçom')"
              :instruction="
                $t(
                  'Quando habilitado, a loja do tipo Bar irá habilitar opção da gorjeta pro garçom'
                )
              "
            />
          </b-col>
        </b-row>

        <b-row v-show="store.waiterComission">
          <b-col md="5">
            <b-col
              md="12"
              class="d-flex justify-content-end mb-1"
            >
              <e-button
                variant="primary"
                :text="$t('Adicionar gorjeta')"
                @click="onAddWaiterComission"
              />
            </b-col>

            <b-col md="12">
              <b-table
                id="stores-waiter-comission-table"
                class="bordered"
                responsive
                striped
                show-empty
                :items="getStoreWaiterComissions"
                :fields="waiterComissionFields"
              >
                <!-- :empty-text="getEmptyTableAddMessage($tc('ADDRESS.CITY_NAME'), 'female')" -->
                <template #cell(actions)="row">
                  <e-grid-actions
                    :show-update="false"
                    :show-delete="true"
                    @delete="onRemoveWaiterComission(row.item)"
                  />
                </template>

                <template #cell(comissionFee)="row">
                  <FormulateInput
                    :id="`waiter_comission_fee-${row.index}`"
                    v-model="row.item.comissionFee"
                    type="text-percetage"
                    validation="number|min:0.01"
                    name="comissionFee"
                    currency="%"
                    :precision="2"
                    label=""
                    currency-symbol-position="suffix"
                  />
                </template>
              </b-table>
            </b-col>
          </b-col>
        </b-row>
      </b-card-actions>

      <b-card-actions
        no-actions
        :show-loading="loading || busy"
        :title="$t('Parametrizações')"
      >
        <b-row>
          <b-col md="3">
            <FormulateInput
              id="max_cash_amount_pay_box"
              v-model="store.maxCashAmountPayBox"
              name="maxCashAmountPayBox"
              :label="$t('Valor máx. permitido no PDV')"
              type="text-number"
              currency="R$"
              :precision="2"
              :instructions="[
                {
                  text: $t(
                    'Valor máximo em dinheiro permitido na gaveta dos PDVs desta loja, caso o valor seja excedido a tela do caixa será bloqueado e pedirá para realizar uma sangria.'
                  ),
                },
                {
                  text: $t(
                    'Caso não seja informado, o valor de R$ 900 será aplicado como limite padrão.'
                  ),
                },
              ]"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="max_accounts_payable_value_on_pay_box"
              v-model="store.maxAccountsPayableValueOnPayBox"
              name="maxAccountsPayableValueOnPayBox"
              :label="$t('Valor máx. permitido no mês para despesa no PDV')"
              type="text-number"
              currency="R$"
              :precision="2"
              :instructions="[
                {
                  text: $t('Valor máximo permitido do PDV para criar despesa no mês.'),
                },
                {
                  text: $t(
                    'Caso não seja informado, não haverá limite para criação de despesas no caixa.'
                  ),
                },
              ]"
            />
          </b-col>
          <b-col>
            <FormulateInput
              v-model="store.lockPdvOpening"
              :instruction="
                $t(
                  'Quando habilitado, impedirá a abertura do caixa se o valor digitado for maior que o saldo disponível na conta troco.'
                )
              "
              type="switch"
              :label="$t('Trava na abertura de caixa')"
            />
          </b-col>
        </b-row>
      </b-card-actions>

      <b-row>
        <b-col md="6">
          <e-address
            ref="address"
            v-model="store.address"
            :show-map="true"
            :show-lat-lng="true"
            :show-loading="loading || busy"
          />
        </b-col>
        <b-col md="6">
          <b-card-actions
            ref="main-card"
            no-actions
            :show-loading="loading || busy"
            :title="$t('Horários de Funcionamento')"
          >
            <opening-hours />
          </b-card-actions>
          <b-card-actions
            ref="main-card"
            no-actions
            :show-loading="loading || busy"
            :title="$t('Telefones')"
          >
            <FormulateInput
              v-slot="{ index }"
              ref="telephoneGroup"
              v-model="store.telephones"
              name="telephones"
              type="group"
              error-behavior="live"
              :repeatable="true"
              :add-label="$t('Adicionar Telefone')"
            >
              <b-row>
                <b-col md="5">
                  <FormulateInput
                    :id="`store-telephone-type-${index}`"
                    type="vue-select"
                    :name="`type`"
                    class="required"
                    :label="$t('Tipo')"
                    :options="telephoneTypes()"
                  />
                </b-col>
                <b-col md="7">
                  <FormulateInput
                    :id="`store-telephone-number-${index}`"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    type="text"
                    :name="`number`"
                    class="required"
                    validation="optional|min:14"
                    maxlength="15"
                    :instruction="$t('Exemplo: (99) 99999-9999')"
                    :label="$t('Telefone')"
                  />
                </b-col>
              </b-row>
            </FormulateInput>
          </b-card-actions>
        </b-col>
      </b-row>

      <FAB
        :main-tooltip="$t('Ações')"
        main-icon="keyboard_command_key"
        :actions="actions"
        :fixed-tooltip="true"
        :busy="busy"
        @save="save"
        @cancel="cancel"
        @active="activateDeactivateStore"
        @inactive="activateDeactivateStore"
        @export="exportStore"
        @import="importStore"
      />
    </FormulateForm>

    <delivery-location-sidebar
      ref="deliveryLocationSidebar"
      @add-item="onAddDeliveryLocation"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { BRow, BCol, BTable, BCard } from 'bootstrap-vue'
import {
  formulateHelper,
  storeDomains,
  stringUtils,
  taxRegimes,
  telephoneTypes,
  uploader,
} from '@/mixins'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { getUserData } from '@/auth/utils'
import {
  EAddress,
  EStoreCombo,
  ECompanyCombo,
  EStatusBadge,
  EButton,
  EGridActions,
  FAB,
} from '@/views/components'
import OpeningHours from './components/OpeningHours.vue'
import DeliveryLocationSidebar from './components/DeliveryLocationSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCardActions,
    BCard,
    EAddress,
    EStatusBadge,
    OpeningHours,
    FAB,
    EButton,
    DeliveryLocationSidebar,
    EGridActions,
    EStoreCombo,
    ECompanyCombo,
  },
  mixins: [formulateHelper, stringUtils, taxRegimes, uploader, storeDomains, telephoneTypes],
  data() {
    return {
      loading: false,
      busy: false,
      userData: getUserData(),
      imageWideLocal: [],
      imageSquareLocal: [],
      digitalCertificateLocal: [],
      digitalCertificateChanged: false,
    }
  },
  computed: {
    ...mapState('pages/store/storeMaintain', ['store', 'heads', 'apiData']),
    ...mapState('pages/company/companyMaintain', ['company']),
    ...mapGetters('pages/store/storeMaintain', [
      'getStoreDeliveryLocations',
      'getStoreWaiterComissions',
      'imageWide',
      'imageSquare',
      'digitalCertificate',
      'digitalCertificateName',
      'headOptions',
      'directorOptions',
      'directorCommercialOptions',
      'investorsOptions',
    ]),
    isEdition() {
      return !!this.$router.currentRoute.params.id
    },

    deliveryLocationFields() {
      return [
        {
          key: 'actions',
          label: this.$t('Ações'),
          class: 'text-center',
          thStyle: { width: '80px' },
        },
        {
          key: 'city',
          label: this.$t('Cidade'),
          formatter: (val, index, item) => `${val} - ${item.province || ''}`,
        },
      ]
    },
    waiterComissionFields() {
      return [
        {
          key: 'actions',
          label: this.$t('Ações'),
          class: 'text-center',
          thStyle: { width: '80px' },
        },
        {
          key: 'comissionFee',
          label: this.$t('Comissão (%)'),
          formatter: val => this.$options.filters.percentage(val),
        },
      ]
    },

    enableDigitalCertificatePassword() {
      return this.digitalCertificateChanged && this.digitalCertificateLocal?.length
    },
    actions() {
      const actions = [
        {
          name: 'cancel',
          icon: 'arrow_back',
          color: 'red',
          tooltip: this.$t('Voltar'),
        },
      ]

      if (this.isEdition) {
        actions.push({
          name: 'export',
          icon: 'download',
          color: 'green',
          tooltip: this.$t('Exportar'),
        })
      } else {
        actions.push({
          name: 'import',
          icon: 'upload',
          color: 'green',
          tooltip: this.$t('Importar'),
        })
      }

      if (this.isEdition && this.$can('Activate', 'Store') && !this.store.active) {
        actions.push({
          name: 'active',
          icon: 'toggle_on',
          color: 'green',
          tooltip: this.$t('Ativar'),
        })
      }
      if (this.isEdition && this.$can('Deactivate', 'Store') && this.store.active) {
        actions.push({
          name: 'inactive',
          icon: 'toggle_off',
          color: 'red',
          tooltip: this.$t('Inativar'),
        })
      }
      actions.push({
        name: 'save',
        icon: 'save',
        tooltip: this.$t('Salvar'),
      })
      return actions
    },
  },
  watch: {
    imageWide(val) {
      this.imageWideLocal = val ? [val] : []
    },
    imageSquare(val) {
      this.imageSquareLocal = val ? [val] : []
    },
    digitalCertificate(val) {
      this.digitalCertificateLocal = val ? [val] : []
    },
    digitalCertificateLocal(val) {
      if (!this.loading) {
        this.digitalCertificateChanged = !!val
      }
      if (!val?.length) {
        this.store.digitalCertificateDueDate = null
      }
    },
  },
  async mounted() {
    this.$store.dispatch('pages/store/storeMaintain/clearStore')
    this.loading = true
    Promise.all([
      this.getData(),
      this.fetchHeads(),
      this.fetchInvestors(),
      this.fetchDirectors(),
      this.fetchDirectorsCommercial(),
    ]).finally(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapActions('app', { appFetchStores: 'fetchStores' }),
    ...mapActions('pages/company/companyMaintain', ['fetchCompany']),
    ...mapActions('pages/store/storeMaintain', [
      'removeDeliveryLocation',
      'addDeliveryLocation',
      'removeWaiterComission',
      'addWaiterComission',
      'fetchHeads',
      'fetchDirectors',
      'fetchDirectorsCommercial',
      'fetchInvestors',
      'setCompanyData',
    ]),
    ...mapMutations('pages/store/storeMaintain', ['SET_STORE']),

    async loadCompanyData(companyId) {
      const company = await this.fetchCompany(companyId)
      this.setCompanyData(company)
    },
    generateValidation(index) {
      // Gera a string de validação
      const saleValueFrom = this.store.shippingFees.shippingRanges[index]?.saleValueFrom || 0
      return `optional|min:${saleValueFrom}`
    },
    isDiscountPercent(index) {
      return this.store.shippingFees?.shippingRanges[index]?.valueType === 'Percentage'
    },

    greaterThanValidation(index) {
      return value => {
        const saleValueFrom = this.store.shippingFees.shippingRanges[index]?.saleValueFrom || 0
        return (
          parseFloat(value || 0) > parseFloat(saleValueFrom) ||
          "O valor de 'Até' deve ser maior que o valor de 'De'."
        )
      }
    },

    validateToGreaterThanFrom(value, values) {
      const fromValue = parseFloat(values.saleValueFrom)
      const toValue = parseFloat(value)

      if (Number.isNaN(fromValue) || Number.isNaN(toValue)) {
        return 'Valores inválidos'
      }

      if (toValue <= fromValue) {
        return "O valor 'Até' deve ser maior que o valor 'De'."
      }

      return true // Validação passou
    },

    async getData() {
      if (this.$router.currentRoute.params.companyId) {
        this.loadCompanyData(this.$router.currentRoute.params.companyId)
      }

      if (this.$router.currentRoute.params.id) {
        try {
          this.loading = true
          await this.$store.dispatch(
            'pages/store/storeMaintain/fetchStore',
            this.$router.currentRoute.params.id
          )
        } catch (error) {
          this.showGenericError({ error })
          this.$router.push({ name: 'store-list' })
        } finally {
          this.loading = false
        }
      }
    },

    onShowDeliveryLocationSidebar() {
      this.$refs.deliveryLocationSidebar.showCreate()
    },
    onAddDeliveryLocation(formData) {
      const indexFound = this.getStoreDeliveryLocations.findIndex(
        loc => loc.city === formData?.city && loc.province === formData?.province
      )
      if (indexFound > -1) {
        this.showError({ message: this.$t('A cidade já foi adicionada') })
        return
      }

      this.addDeliveryLocation(formData)
      this.$refs.deliveryLocationSidebar.hideSidebar()
    },
    async onRemoveDeliveryLocation(item) {
      const confirm = await this.confirm({
        title: this.$t('Deseja remover esta cidade?'),
      })
      if (!confirm) return
      this.removeDeliveryLocation(item.id || item.localId)
    },

    onAddWaiterComission() {
      this.addWaiterComission({
        id: null,
        comissionFee: 0,
      })
    },
    async onRemoveWaiterComission(item) {
      const confirm = await this.confirm({
        title: this.$t('Deseja remover esta comissão?'),
      })
      if (!confirm) return

      this.removeWaiterComission(item.id || item.localId)
    },

    cancel() {
      this.$router.go(-1)
      this.$store.dispatch('pages/store/storeMaintain/clearStore')
    },

    async save() {
      this.$refs.form.showErrors()
      const addressValid = this.$refs.address.validate()
      if (this.$refs.form.isValid && addressValid) {
        // input de grupo do formulate ocorre bug com "validation required" nos inputs dentro qnd adiciona e depois remove um item.
        // Para evitar, foi feito verificação manual.
        if (!this.validateTelephones()) {
          this.showInvalidDataMessage({ message: this.$t('Em telefones') })
          return
        }

        const { telephones: apiTelephones } = this.apiData
        const { telephones } = this.store
        const notInTelephonesForm = tApi => telephones.findIndex(t => t.id === tApi.id) < 0
        const telephonesDeleted = apiTelephones
          .filter(tApi => notInTelephonesForm(tApi))
          .map(tDeleted => ({ ...tDeleted, isDeleted: true }))

        try {
          this.busy = true
          const uploadResult = await this.uploadImages()
          const [imageWide, imageSquare] = uploadResult
          this.store.imageWide = imageWide.url
          this.store.imageSquare = imageSquare.url

          const data = {
            ...this.store,
            telephones: [...telephones, ...telephonesDeleted],
            taxCode: this.store.taxCode || null,
            maxProductItemsSell: this.store.maxProductItemsSell || null,
            minDeliveryScheduleMinutes: this.store.minDeliveryScheduleMinutes || null,
            zeroRateMinValue: this.store.zeroRateMinValue || null,
          }

          await this.$http({
            url: '/api/stores',
            method: this.isEdition ? 'PUT' : 'POST',
            data,
          })
          this.showSuccess({ message: this.$t('Loja salva com sucesso') })
          await this.appFetchStores()
          if (this.$router.currentRoute.params.companyId) {
            this.$router.push({
              name: 'company-maintain',
              params: { id: this.$router.currentRoute.params.companyId },
            })
          } else {
            this.$router.push({ name: 'store-list' })
            this.$store.dispatch('pages/store/storeMaintain/clearStore')
            this.$store.dispatch('pages/store/storeMaintain/fetchStores')
          }
        } catch (error) {
          this.showError({ error, message: this.$t('Não foi possível concluir a operação') })
        } finally {
          this.busy = false
        }
      } else {
        this.showInvalidDataMessage()
      }
    },

    async uploadImages() {
      let resultWide = null
      let resultSquare = null
      const result = await this.upload({
        files: [...this.imageWideLocal, ...this.imageSquareLocal],
        destinationPath: 'images/stores',
      })
      if (this.imageWideLocal.length) {
        ;[resultWide] = result
      }
      if (this.imageSquareLocal.length) {
        ;[resultSquare] = result.slice(result.length > 1 ? 1 : 0)
      }
      const nullResult = { url: null }
      return [resultWide || nullResult, resultSquare || nullResult]
    },

    validateTelephones() {
      const { telephones } = this.store
      let isValid = true

      if (telephones.length > 0) {
        isValid = !telephones.some(t => !(t.number && t.type))
      }

      return isValid
    },

    async activateDeactivateStore() {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          const { id, active } = this.store

          const path = active ? 'deactivate' : 'activate'
          await this.$http({
            url: `/api/stores/${path}/${id}`,
            method: 'PUT',
          })
          this.getData()
          this.showSuccess({
            message: this.$t(`${active === true ? 'Inativado' : 'Ativado'} com sucesso`),
          })
          await this.appFetchStores()
        }
      } catch (error) {
        this.showError({ error })
      }
    },

    exportStore() {
      // Convert JSON object to string
      const jsonString = JSON.stringify(this.store)

      // Create a blob with the JSON string
      const blob = new Blob([jsonString], { type: 'application/json' })

      // Create a link for downloading the file
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `Store_${this.$options.filters.storeName(
        this.store
      )}_${moment().format()}.json`

      // Click the link to start downloading the file
      link.click()

      // Clean up the link and blob objects
      URL.revokeObjectURL(link.href)
      link.remove()
    },

    importStore() {
      // Create an input element for selecting files
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = 'application/json'

      // Add an event listener for when a file is selected
      input.addEventListener('change', () => {
        const file = input.files[0]

        // Use a FileReader to read the contents of the file
        const reader = new FileReader()
        reader.onload = () => {
          try {
            const jsonObj = JSON.parse(reader.result)

            // Do something with the loaded JSON object

            this.SET_STORE(jsonObj)
          } catch (e) {
            this.showError({ message: this.$t('Não foi possível ler o arquivo') })
            console.error('Failed to parse JSON from file', e)
          }
        }
        reader.readAsText(file)
      })

      // Click the input element to trigger the file selection dialog
      input.click()
    },
    recalculateRanges(added) {
      const ranges = this.store.shippingFees.shippingRanges
      if (added) {
        // Verifica se há pelo menos duas faixas para validar
        if (ranges.length > 1) {
          const penultimateRange = ranges[ranges.length - 2]

          // Verifica se a penúltima faixa tem o valor `saleValueTo` preenchido
          if (!penultimateRange.saleValueTo) {
            // Remove a última faixa adicionada
            ranges.pop()
            this.$set(this.store.shippingFees, 'shippingRanges', ranges)

            this.showWarning({
              message: this.$t(
                'Preencha o campo "Até" da última faixa antes de adicionar uma nova'
              ),
            })
          }
        }
        for (let i = 0; i < ranges.length; i += 1) {
          ranges[i].valueType = ranges[i].valueType ?? 'Value'
          if (i === 0) {
            // A primeira faixa começa sempre em 0
            ranges[i].saleValueFrom = 0
          } else {
            // A faixa seguinte começa 0.01 maior que o valor "Até" da faixa anterior
            const previousRange = ranges[i - 1]
            ranges[i].saleValueFrom = previousRange.saleValueTo
              ? parseFloat(previousRange.saleValueTo) + 0.01
              : 0
          }
        }
      }
    },
    canAddNewRange() {
      const lastRange = this.store.shippingFees?.shippingRanges?.at(-1)
      return lastRange?.saleValueTo !== null && lastRange?.saleValueTo !== ''
    },
  },
}
</script>
<style>
.bg-light-gray {
  background-color: #f2f2f2;
}
</style>
